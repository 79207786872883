<template>
  <BasicModal
    :show-action-button="false"
    :show-cancel-button="false"
  >
    <div class="announcement">
      <img src="@/assets/images/rocket.png" />
      <h4>Introducing event monitoring</h4>
      <p>
        Get real-time alerts for the smart contract events you care about with
        our newest Trigger
      </p>
      <ul>
        <li>
          <img src="@/assets/images/smart-contract-announcement-modal/contract-bullet.png" />
          Add Ethereum, Polygon, &amp; Avalanche contracts
        </li>
        <li>
          <img src="@/assets/images/smart-contract-announcement-modal/event-bullet.png" />
          Choose the events you want to get alerts for
        </li>
        <li>
          <img src="@/assets/images/smart-contract-announcement-modal/alert-bullet.png" />
          Choose where you want to receive the alerts
        </li>
      </ul>
      <div class="buttons">
        <button class="small tertiary" @click="close">Not now</button>
        <a target="_blank" href="https://blog.esprezzo.io/introducing-event-monitor-smart-contract-alerts-made-easy" class="button small" @click="close">Check it out</a>
      </div>
    </div>
  </BasicModal>
</template>

<script>

  import BasicModal from '@/components/modals/_BasicModal.vue'

  export default {
    components: {
      BasicModal,
    },
    methods: {
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .announcement
    @apply flex
    @apply flex-col
    @apply items-center

    h4
      @apply my-2
      @apply text-center

    p
      @apply mb-4
      @apply text-lg
      @apply font-bold
      @apply text-center
      @apply text-gray-600

    ul
      @apply m-0
      @apply list-none
      @apply space-y-3

      li
        @apply leading-8

        @apply flex
        @apply items-start

      img
        @apply mr-2

    .buttons
      @apply mt-6
      @apply flex
      @apply space-x-4

</style>
